import React from "react";
import { Button } from "react-bootstrap";
import Section from "./Section";
import Img from "gatsby-image";
import CallToActionButton from "../components/CallToActionButton";

const Services = ({ services, footer }) => {
  return (
    <div className={`services-container ${footer ? 'footered' : 'sectioned'}`}>
      {services?.map((service, index) => (
        <div key={index} className="service">
          <h4>{service.title}</h4>
          <p>{service.subTitle}</p>
          <CallToActionButton
            link={service.url}
            text={service.btnText}
            size="small"
            btnClass="btn btn-secondary"
          />
        </div>
      ))}
    </div>
  );
};

export default Services;
