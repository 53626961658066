import React from "react";
import { graphql, navigate } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import appendQueryString from "../../utils/query";
import HeroCard from "../../components/HeroCard";
import { ListGroup } from "react-bootstrap";
import Services from "../Services";

const Learn = (props) => {
  const learnContent = props.data.allMarkdownRemark.edges;
  const { indexData } = props.data;
  const { html } = indexData;
  const { services } = props.data.services.frontmatter;

  // Extract the pages into a map, grouped by category
  const categoryPageMap = {};
  learnContent.map((edge) => {
    if (edge.node.frontmatter.category === undefined) return;

    let pageList = categoryPageMap[edge.node.frontmatter.category];
    if (pageList === undefined) {
      pageList = [];
      categoryPageMap[edge.node.frontmatter.category] = pageList;
    }

    pageList.push(edge);
  });

  return (
    <Layout bodyClass="page-learn">
      <SEO
        title={indexData.frontmatter.title}
        description={indexData.frontmatter.seoDescription}
        path={indexData.frontmatter.path}
      />
      <HeroCard
        image={indexData.frontmatter.headerImage}
        title={indexData.frontmatter.headerTitle}
        subtitle={indexData.frontmatter.headerSubtitle}
        size="medium"
      />
      {html && (
        <Container className="content-container">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: indexData.html }}
          />
        </Container>
      )}
      <Container className="categories">
        {Object.keys(categoryPageMap).map((categoryName) => {
          const pageList = categoryPageMap[categoryName].map(
            (pageNode) => pageNode.node.frontmatter
          );
          return (
            <div key={categoryName} className="category">
              <h4>{categoryName}</h4>
              <ListGroup>
                {pageList.map((page) => (
                  <ListGroup.Item
                    key={page.path}
                    onClick={() => navigate(appendQueryString(page.path))}
                  >
                    {page.title}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          );
        })}
      </Container>
      <Container>
        <Services services={services} footer />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query LearnContentQuery {
    allMarkdownRemark(
      filter: {frontmatter: {isVisible: {eq: true}}, fileAbsolutePath: { regex: "/\\/learn\\/[\\w-]+.md/" } }, 
      sort: {fields: [frontmatter___category, frontmatter___title], order: ASC}) {
      edges {
        node {
          frontmatter {
            title
            path
            category
          }
        }
      }
    }
    services: markdownRemark(fileAbsolutePath: { regex: "/services.md/" }) {
      frontmatter {
        services {
          btnText
          subTitle
          title
          url
        }
      }
    }
    indexData: markdownRemark(fileAbsolutePath: {regex: "/learn-index.md/"}) {
      frontmatter {
        title
        path
        seoDescription
        headerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerTitle
        headerSubtitle
      }
      html
    }
  }
`;

export default Learn;
